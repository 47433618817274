import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import { Autocomplete, Box, Checkbox, Chip, Grid, SvgIcon, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "../../../../Assets/Styles/Containers/_button.scss";
import "../../../../Assets/Styles/Containers/_inspection.scss";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { convertFontToSpan, filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";
import FroalaWithoutDropdown from "../../../Common/HtmlEditor/FroalaWithoutDropdown";
import { arrowDown } from "../../../../Assets/Svg/Svg";

const froalaConfig = {
  imagePaste: false,
  spellCheck: true,
  heightMin: 201,
  heightMax: 300,
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

const toolbarButtonsConfig = {
  moreText: {
    buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"],
    align: "left",
    buttonsVisible: 3,
  },
  moreRich: {
    buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"],
    align: "left",
    buttonsVisible: 0,
  },
  moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
};

const AddCommentModal = ({
  showCommentModal,
  setShowCommentModal,
  editComment,
  setEditComment,
  templateId,
  categoryId,
  lineUuid,
  prompt,
  recommendation,
  commentUuid,
  is_mannual_prompt,
  disableCommentBtn,
  setDisableCommentBtn,
  lineName,
  categoryName,
}) => {
  const storeData = useSelector((state) => state.CommonReducer);
  const editorRef = useRef();
  const [editorContent, setEditorContent] = useState({ innerHTML: "" });
  const [commentTitle, setCommentTitle] = useState("");
  const [inspectionData, setInspectionData] = useState("");
  const [showAIModal, setShowAIModal] = useState(false);
  const [isCopy, setIsCopy] = useState(true);
  const [tagOpen, setTagOpen] = useState(false);
  const [tag, setTag] = useState(storeData?.clTag !== undefined ? storeData?.clTag : []);
  const [commentTag, setCommentTag] = useState([]);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowCommentModal(false);
    setEditComment(false);
    setEditorContent({ innerHTML: "" });
    setCommentTitle("");
  };
  const handleChange = (name, content) => {
    setEditorContent({
      innerHTML: content.replace(/<([a-zA-Z0-9]+)>\s*<\/\1>\s*/g, ""),
    });
    setIsCopy(false);
  };

  const createNewComment = async (inspectionData, newCommentAdded) => {
    setDisableCommentBtn(true);
    let templateIndex = inspectionData?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let prevData = JSON.parse(JSON.stringify(inspectionData));
    delete prevData?.report_media_library_files;
    let commentIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex((itm) => itm.uuid == commentUuid);
    if (!editComment) {
      let newComment = {
        comment_id: null,
        uuid: uuidv4(),
        prompt: commentTitle,
        recommendation: newCommentAdded === undefined ? "" : newCommentAdded,
        defaultRating_id: 1,
        default_location: "",
        is_deleted: 0,
        is_selected: 1,
        comment_input_controls: [
          {
            data: [],
            type: "Location",
            uuid: uuidv4(),
            prompt: "Location",
            values: [],
            created_at: await GetUTCTime(),
            updated_at: await GetUTCTime(),
            comment_input_control_id: null,
          },
          {
            data: [],
            type: "Rating",
            uuid: uuidv4(),
            prompt: "Rating",
            values: [],
            created_at: await GetUTCTime(),
            updated_at: await GetUTCTime(),
            comment_input_control_id: null,
          },
          {
            data: [],
            type: "Media",
            uuid: uuidv4(),
            prompt: "Media",
            values: [],
            created_at: await GetUTCTime(),
            updated_at: await GetUTCTime(),
            comment_input_control_id: null,
          },
        ],
        updated_at: await GetUTCTime(),
        created_at: await GetUTCTime(),
      };
      prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments.push(newComment);
      let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
      UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
      setShowCommentModal(false);
    } else {
      if (prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].is_mannual_prompt !== undefined) {
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].prompt = commentTitle;
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].recommendation = newCommentAdded;
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].is_mannual_prompt = 1;
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].updated_at = await GetUTCTime();
      } else {
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].prompt = commentTitle;
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].recommendation = newCommentAdded;
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].updated_at = await GetUTCTime();
      }
      let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
      UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
      setShowCommentModal(false);
    }
    setIsCopy(true);
  };

  useEffect(() => {
    if (editComment) {
      if (is_mannual_prompt !== undefined) {
        if (is_mannual_prompt === 0) setCommentTitle("");
        else setCommentTitle(prompt || "");
      } else setCommentTitle(prompt || "");
      setEditorContent({ innerHTML: recommendation || "" });
    } else {
      setCommentTitle("");
      setEditorContent("");
    }
  }, [editComment, storeData.inspectionData]);

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionData(storeData.inspectionData?.data);
      setCommentTitle(prompt || "");
      setEditorContent({ innerHTML: recommendation || "" });
    }
  }, [storeData.inspectionData, editComment, commentUuid]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Dialog className="comment-dialog-non-narrative" open={showCommentModal} onClose={handleClose} aria-labelledby="category-modal" aria-describedby="category-modal-description">
      <DialogTitle pb={0}>
        <Grid container display={"flex"}>
          <Grid item xs={11} display={"flex"} justifyContent={"flex-start"}>
            <Typography className="non-narrative-comment-title">{`${categoryName ? categoryName + " -" : ""} ${editComment ? "Edit comment" : "Add comment"}`}</Typography>
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
            <div className="close-wrapper-new-category-right" style={{ marginTop: "10px" }}>
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              value={0}
              className="non-narrative-comment-tabs"
              // onChange={handleChange}
              aria-label="basic tabs example">
              <Tab label={editComment ? "Edit comment" : "New comment"} id={`simple-tab-0`} className="non-narrative-comment-tab" aria-controls={`simple-tabpanel-0`} />
            </Tabs>
            <Divider />
            <div
              role="tabpanel"
              // hidden={value !== index}
              // id={`simple-tabpanel-${index}`}
              // aria-labelledby={`simple-tab-${index}`}
              // {...other}
            >
              <Grid item xs={12} my={2}>
                <FormControl variant="standard" className="forms-control">
                  <label className="non-narrative-comment-label" htmlFor="title">
                    Title
                  </label>
                  <TextField id="commentTitle" size="small" variant="outlined" placeholder="Comment title" value={commentTitle} onChange={(e) => setCommentTitle(e.target.value)} type="text" />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
        <FormControl variant="standard" className="forms-control">
         <label className="cl-new-comment-input-form-label" htmlFor="tags">
          TAGS
         </label>
         <Autocomplete
          className="forms-control-tag custom-color autocompleteCustom"
          id="tags-outlined"
          multiple
          size="small"
          limitTags={2}
          options={tag}
          onClick={() => (!tagOpen ? setTagOpen(true) : setTagOpen(false))}
          disableCloseOnSelect
          getOptionLabel={(option) => option?.name}
          open={tagOpen}
          sx={{
           "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#ccc" },
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ccc" },
           },
          }}
          onChange={(e, value) => setCommentTag(value)}
          renderTags={(value, getTagProps) =>
           value.map((option, index) => (
            <Chip variant="outlined" deleteIcon={<CloseOutlinedIcon />} label={option?.name} {...getTagProps({ index })} sx={{ padding: "2px 2px 2px 10px !important", fontSize: "10px !important" }} />
           ))
          }
          renderOption={(props, option, { selected }) => (
           <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
            <Box
             sx={{
              width: "12px",
              height: "12px",
              backgroundColor: option?.color,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             }}
             style={{ marginRight: 8 }}
            />
            {option?.name}
           </li>
          )}
          renderInput={(params) => (
           <TextField
            {...params}
            variant="outlined"
            placeholder={commentTag?.length === 0 ? "Add tags" : ""}
            onClick={() => (!tagOpen ? setTagOpen(true) : setTagOpen(false))}
            onBlur={() => setTagOpen(false)}
            InputProps={{
             ...params.InputProps,
             style: {
              fontFamily: "Open Sans!important",
              fontSize: "14px",
              fontWeight: 400,
              paddingLeft: commentTag?.length !== 0 ? "0px" : "20px",
             },
             endAdornment: (
              <>
               <SearchIcon className={`customSearch ${commentTag?.length !== 0 ? "hidden" : ""}`} style={{ color: "#9b9b9b", marginRight: "8px" }} />
               <SvgIcon className={`customArrowDown ${tagOpen ? "arrowActive" : ""}`} viewBox="" onClick={() => (!tagOpen ? setTagOpen(true) : setTagOpen(false))}>
                {arrowDown}
               </SvgIcon>
              </>
             ),
            }}
           />
          )}
         />
        </FormControl>
       </Grid> */}
              <Grid container className="input-controll">
                <Grid item xs={12} sm={12}>
                  <FormControl variant="standard" className="forms-control">
                    <label className="non-narrative-comment-label" htmlFor="Comment">
                      Comment
                    </label>
                    <FroalaWithoutDropdown
                      copy={isCopy}
                      showAI={storeData?.inspectionData?.data?.is_comment_helper_enable}
                      commentData={recommendation}
                      editorData={convertFontToSpan(editorContent)}
                      editor={editorRef}
                      id={"non-narrative-comment"}
                      onChange={handleChange}
                      froalaConfig={froalaConfig}
                      toolbarButtonsConfig={toolbarButtonsConfig}
                      showAIModal={showAIModal}
                      setShowAIModal={setShowAIModal}
                      lineName={lineName}
                      categoryName={categoryName}
                      commentTitle={commentTitle}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className="non-narrative-dialog-actions">
        <Button variant="outlined" className="non-narrative-comment-cancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className="non-narrative-comment-save"
          disabled={disableCommentBtn || (commentTitle === "" && editorContent?.innerHTML === "")}
          onClick={() => createNewComment(inspectionData, editorContent?.innerHTML)}>
          {`${editComment ? "Edit comment" : "Add new comment"}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCommentModal;
