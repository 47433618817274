import { Modal, Paper, Collapse, Grid, Typography, TextField, Button, FormControlLabel, Checkbox } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BulbIcon from "../../../../Assets/Images/AI_COMMENT_ICON.png";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import "../../../../Assets/Styles/Containers/_aiComment.scss";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import { origin } from "../../../Config/Api-constants";
import RestoreIcon from "@mui/icons-material/Restore";
import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Base64 } from "js-base64";
import toast from "react-hot-toast";
import axios from "axios";

const AICommentHelper = ({ categoryName, lineName, categoryNameFromLocation, showAIModal, setShowAIModal, editor, id, setCommentText, open, onClose, showAI, commentTitle, handleSetComment }) => {
  const [generated, setGenerated] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableGenerate, setDisableGenerate] = useState(commentTitle ? false : true);
  const [values, setValues] = useState({ title: commentTitle ? commentTitle : "", comment: "" });
  const [toggleRecommendation, setToggleRecommendation] = useState(false);
  const [quota, setQuota] = useState({ limitLeft: null, quotaLimit: null });
  const [toggleLongerText, setToggleLongerText] = useState(false);
  const [history, setHistory] = useState([]);
  const divRef = useRef(null);
  useEffect(() => {
    handleTitleChange(commentTitle);
  }, [commentTitle]);

  const getQuotaLimit = async () => {
    let baseUrl = await origin();
    let sessionAPIKey = sessionStorage.getItem("CK");
    let sessionSecretKey = sessionStorage.getItem("SK");
    let inspectionGuid = sessionStorage.getItem("guid");
    let associateCompany = sessionStorage.getItem("asoCom");
    const params = {
      inspectionGuid: inspectionGuid,
      associateCompany: associateCompany,
    };
    try {
      const response = await axios.post(`${baseUrl}get-quota-limit`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
          "api-key": `${Base64.decode(sessionAPIKey)}`,
          platform: "web",
        },
      });
      setQuota((prevValues) => ({
        ...prevValues,
        limitLeft: response?.data?.data?.limitLeft,
        quotaLimit: response?.data?.data?.quotaLimit,
      }));
    } catch (error) {
      console.log("response error", error);
    }
  };

  const generateComment = async () => {
    setLoading(true);
    let baseUrl = await origin();
    let sessionAPIKey = sessionStorage.getItem("CK");
    let sessionSecretKey = sessionStorage.getItem("SK");
    let inspectionGuid = sessionStorage.getItem("guid");
    let associateCompany = sessionStorage.getItem("asoCom");
    const params = {
      title: values?.title,
      lineName: lineName || "",
      inspectionGuid: inspectionGuid,
      associateCompany: associateCompany,
      categoryName: categoryName || categoryNameFromLocation || "",
      isLonger: toggleLongerText ? 1 : 0,
      includeRecommendation: toggleRecommendation ? 1 : 0,
    };
    try {
      const response = await axios.post(`${baseUrl}generate-comment`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
          "api-key": `${Base64.decode(sessionAPIKey)}`,
          platform: "web",
        },
      });
      setValues((prevValues) => ({
        ...prevValues,
        comment: response.data.data?.generatedComment, // Update the title value
      }));
      setQuota((prevValues) => ({
        ...prevValues,
        limitLeft: response?.data?.data?.limitLeft,
        quotaLimit: response?.data?.data?.quotaLimit,
      }));
      const newEntry = { id: (history.length + 1).toString(), comment: response.data.data?.generatedComment }; // New entry
      const updatedHistory = [...history, newEntry]; // Add new comment to history
      setHistory(updatedHistory); // Update the state
      storeHistoryInSession(updatedHistory); // Update sessionStorage
      setGenerated(true);
      setLoading(false);
    } catch (error) {
      console.log("response error", error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  // Function to retrieve MockHistory from sessionStorage
  const getHistoryFromSession = () => {
    const storedHistory = sessionStorage.getItem("mockHistory");
    if (storedHistory) {
      setHistory(JSON.parse(storedHistory)); // Parse the string back to an array
    }
  };

  // Store data in sessionStorage on component mount
  useEffect(() => {
    // storeHistoryInSession();
    getQuotaLimit();
    getHistoryFromSession(); // Retrieve stored data immediately after storing it
  }, []);

  const CloseModal = () => {
    onClose(false);
    setShowAIModal(false);
    setGenerated(false);
    setIsCollapseOpen(false);
    // setDisableGenerate(true);
    setToggleLongerText(false);
    setToggleRecommendation(false);
    if (commentTitle?.trim()?.length > 0) {
      setDisableGenerate(false);
      setValues((prevValues) => ({
        ...prevValues,
        title: commentTitle, // Update the title value
        comment: "",
      }));
    } else setValues({ title: "", comment: "" });
  };
  const ToggleCollapse = () => {
    setIsCollapseOpen(!isCollapseOpen); // Toggle collapse on button click
  };

  useEffect(() => {
    if (values?.title?.trim().length === 0) {
      setDisableGenerate(true);
    } else if (values?.title?.trim().length > 0) {
      setDisableGenerate(false);
    }
  }, [values.title]);

  const handleTitleChange = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      title: e, // Update the title value
    }));
  };

  const handleCommentChange = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      comment: e.target.value, // Update the title value
    }));
  };

  // Function to store MockHistory in sessionStorage
  const storeHistoryInSession = (value) => {
    sessionStorage.setItem("mockHistory", JSON.stringify(value));
  };
  // Add new comment to the history
  const handleAddComment = () => {
    if (values?.title.trim()) {
      // const newEntry = { id: (history.length + 1).toString(), comment: values?.title }; // New entry
      // const updatedHistory = [...history, newEntry]; // Add new comment to history
      // setHistory(updatedHistory); // Update the state
      // storeHistoryInSession(updatedHistory); // Update sessionStorage
      // setValues({ title: "", comment: "" }); // Clear the input field
      generateComment();
    }
  };

  const switchToThisComment = (value) => {
    setDisableGenerate(false);
    setValues((prevValues) => ({
      ...prevValues,
      comment: value,
    }));
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleLongerTextFilter = (event) => {
    setToggleLongerText(event.target.checked);
  };

  const handleRecommendationFilter = (event) => {
    setToggleRecommendation(event.target.checked);
  };

  return (
    <Modal open={showAIModal} className="ai-modal-main">
      <Paper className="ai_comment_modal_container">
        <Grid container>
          <Grid item xs={12}>
            <CloseIcon className="ai_modal_close_cross" onClick={CloseModal} />
          </Grid>
          <Grid item xs={12} className="center_align">
            <div style={{ borderRadius: "50%", width: "50px", height: "50px" }}>
              <img src={BulbIcon} alt="ai_comment" />
            </div>
          </Grid>
          <Grid item xs={12} className="center_align">
            <Button
              className="ai_beta_button"
              endIcon={<AutoAwesomeIcon sx={{ height: "12.04px", width: "17.71px", ml: -1 }} />}
              sx={{ padding: "0px 0px 0px 10px" }} // Adjust button padding if necessary
            >
              <p style={{ fontSize: "10px" }}>BETA</p>
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "6px", mt: -5 }}>
            <Typography
              className="ai_title"
              sx={{ textAlign: "center" }} // Centers the text within the Typography element
            >
              AI Comment Helper
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: "10px" }} className="center_align">
            <Typography className="ai_subtitle">Welcome to the comment helper. Tap to generate a new comment, and then edit it here.</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className="ai-modal-main "
            sx={{
              p: "25px 62px",
              overflowY: "scroll !important",
              maxHeight: "500px",
              scrollbarWidth: "none", // For Firefox
              "&::-webkit-scrollbar": {
                display: "none", // For Chrome, Safari, and Edge
              },
            }}>
            <div className="ai_subdiv" ref={divRef}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className="ai_desc">Title</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    onChange={(e) => handleTitleChange(e.target.value)}
                    value={values?.title}
                    sx={{
                      marginBottom: "16px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "6px",
                          border: "2px solid #d5d5d5",
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        backgroundColor: "white", // Set background color to white
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="ai_desc">Be descriptive. The title is used as a prompt for the AI. </Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: "flex" }}>
                  <FormControlLabel control={<Checkbox checked={toggleLongerText} onChange={handleLongerTextFilter} />} label={<p className="p_setting">Longer</p>} />
                  <FormControlLabel control={<Checkbox checked={toggleRecommendation} onChange={handleRecommendationFilter} />} label={<p className="p_setting">Include recommendation</p>} />
                </Grid>
                <Grid item xs={6}>
                  <LoadingButton
                    loading={loading}
                    className="ai_generate_button"
                    disabled={disableGenerate}
                    onClick={handleAddComment}
                    startIcon={generated ? <UTurnLeftIcon sx={{ transform: "rotate(90deg) scaleY(-1)" }} /> : ""}>
                    {generated ? "REGENERATE" : "GENERATE"}
                  </LoadingButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography className="ai_remain">
                    ({quota?.limitLeft}/{quota?.quotaLimit} left)
                  </Typography>
                </Grid>
                {generated ? (
                  <>
                    <Grid item xs={12} sx={{ mt: "30px" }}>
                      <Typography className="ai_desc">Comment</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        value={values?.comment}
                        fullWidth={true}
                        size="small"
                        onChange={handleCommentChange}
                        sx={{
                          marginBottom: "16px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "6px",
                              border: "2px solid #d5d5d5",
                            },
                          },
                        }}
                        InputProps={{
                          sx: {
                            backgroundColor: "white", // Set background color to white
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ pl: 1 }}>
                      <Typography className="ai_desc" sx={{ fontWeight: "900 !important", display: "inline" }}>
                        Disclaimer:{" "}
                      </Typography>
                      <Typography className="ai_desc" sx={{ display: "inline" }}>
                        AI-generated comments are suggestions only. Please review and edit to ensure they meet your standards and requirements.
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </div>
            {/* {generated ? <Grid item xs={12} sx={{ mt: "29px" }}>
                        <div className="ai_subdiv" >
                            <div style={{ display: "flex", gap: "5px", alignItems: "center", cursor: "pointer", userSelect: "none" }} onClick={ToggleCollapse}>
                                <RestoreIcon />
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography className="recent_history_title">Recent history</Typography>
                                   
                                    <KeyboardArrowDownIcon />
                                </div>
                            </div>
                            <Collapse in={isCollapseOpen}>
                                {history?.slice().reverse().map((item) => (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            size="small"
                                            onChange={handleTitleChange}
                                            multiline
                                            value={item.comment}
                                            sx={{
                                                marginTop: "35px",
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderRadius: '6px',
                                                        border: "2px solid #d5d5d5",
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                readOnly: true, // Make the field read-only
                                                sx: {
                                                    backgroundColor: 'white', // Set background color to white
                                                },
                                            }}
                                        />
                                        <Button
                                            className="switch_to_this_comment"
                                            onClick={() => switchToThisComment(item.comment)}
                                            startIcon={<ImportExportIcon />}
                                        >
                                            Switch to this comment
                                        </Button>
                                    </Grid>
                                ))}
                            </Collapse>
                        </div>
                    </Grid> : ""} */}
          </Grid>
          <Grid item xs={12} sx={{ p: "16px", gap: "8px !important", borderTop: "1px solid #d5d5d5" }}>
            <Button
              className="ai_use_comment_button"
              disabled={!generated}
              onClick={() => {
                if (id === "commentText") setCommentText((prevState) => ({ ...prevState, innerHTML: `<div><div>${values.comment}</div></div>` }));
                CloseModal();
                handleSetComment(values.comment);
              }}>
              Use Comment
            </Button>
            <Button className="ai_cancel_button" onClick={CloseModal}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default AICommentHelper;
