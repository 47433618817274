import { Grid, Typography, Divider, FormControl, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import parse from "html-react-parser";
import { useState, useEffect } from "react";
import AddInformation from "./AddInformation";

const Information = ({ data, templateId, categoryId, lineUuid }) => {
  const [information, setInformation] = useState("");
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  useEffect(() => {
    setInformation(data !== null && data !== undefined && data.data !== null && data.data.length !== 0 ? data.data : "");
  }, [data]);
  return (
    <>
      <AddInformation
        showInformationModal={showInformationModal}
        setShowInformationModal={setShowInformationModal}
        information={information[0]}
        setInformation={setInformation}
        templateId={templateId}
        categoryId={categoryId}
        lineUuid={lineUuid}
        inputChanged={inputChanged}
        setInputChanged={setInputChanged}
      />

      {data?.is_disabled != 1 ? (
        <Grid container className="container">
          <Grid item xs={12} className="line_detail_des_title">
            <span className="details_title">Information</span>
            <span className="details_action" id="edit_information" onClick={() => setShowInformationModal(true)}>
              <EditIcon />
              Edit
            </span>
          </Grid>
          <span style={{ lineBreak: "anywhere" }}>{information !== "" ? parse(`${information}`) : "Add extra information about the line."}</span>
          {/* <FormControl sx={{ m: 1,width:'100%',margin:'0'}} variant="outlined">
              <OutlinedInput
                  disabled
                  id="outlined-adornment-weight"
                  className='location-input'
                  size="small"
                  type="text"
                  fullWidth
                  sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black",
                      },
                    }}
                  value={parse(`${information}`)}
                  endAdornment={
                      <InputAdornment position="end">
                      <IconButton    
                          disableRipple               
                          aria-label="toggle password visibility"
                          // onClick={handleClick}
                          edge="end"
                      >
                          {<EditIcon/>} <span className='edit-text'></span>
                      </IconButton>
                      </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Information"
              />
          </FormControl>             */}
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default Information;
