import { Grid, Typography, Divider, Collapse, Rating } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Media from "../Media/Media";
import Location from "../Location/Location";
import CommentRating from "../Rating/CommentRating";
import AddCommentModal from "./AddCommentModal";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import FlagIcon from "@mui/icons-material/Flag";
import { useRef } from "react";
import { filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";

const CommentDetails = (props) => {
  let {
    prompt,
    is_selected,
    recommendation,
    toggle,
    setSelectedImages,
    setSelectedMediaFiles,
    selectedMediaFiles,
    comment_input_controls,
    is_mannual_prompt,
    templateId,
    categoryId,
    uuid,
    categoryUuid,
    lineUuid,
    width,
    setWidth,
    height,
    setHeight,
    commentIndex,
    lineName,
    categoryName,
  } = props;
  const ref = useRef(null);
  const [commentSelected, setCommentSelected] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [inspectionData, setInspectionData] = useState("");
  const [editComment, setEditComment] = useState(false);
  const [disableCommentBtn, setDisableCommentBtn] = useState(false);
  const [ratingDataCheck, setRatingDataCheck] = useState([]);
  // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionData(storeData.inspectionData?.data);
    }
    setRatingDataCheck(comment_input_controls?.find((data) => data.type === "Rating"));
    setCommentSelected(is_selected);
  }, [storeData.inspectionData, commentSelected, uuid]);

  const checkComment = async (e, action) => {
    if (action == "outside") {
      setCommentSelected(e.target.checked);
    }
    setCommentSelected(e.target.checked);
    let templateIndex = inspectionData?.inspectiontemplates?.findIndex((item) => item.template_id === templateId);
    let categoryIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories?.findIndex((item) => item.id === categoryId);
    let lineIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let prevData = JSON.parse(JSON.stringify(inspectionData));
    delete prevData?.report_media_library_files;
    let commentIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex((item) => item.uuid == uuid);
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].is_selected = !commentSelected ? 1 : 0;

    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };

  return (
    <>
      <AddCommentModal
        showCommentModal={showCommentModal}
        setShowCommentModal={setShowCommentModal}
        templateId={templateId}
        categoryId={categoryId}
        lineUuid={lineUuid}
        editComment={editComment}
        setEditComment={setEditComment}
        recommendation={recommendation}
        prompt={prompt}
        commentUuid={uuid}
        is_mannual_prompt={is_mannual_prompt}
        disableCommentBtn={disableCommentBtn}
        setDisableCommentBtn={setDisableCommentBtn}
        lineName={lineName}
        categoryName={categoryName}
      />
      <Grid container className="comment__container" onClick={(e) => !commentSelected && checkComment(e, "outside")} sx={{ ...(commentSelected ? {} : { ":hover": { cursor: "pointer" } }) }}>
        <Grid item xs={12}>
          <Grid className="comment_detail_title">
            <span
              className="comment_title"
              id={`comment_${commentIndex}`}
              onClick={(e) => {
                commentSelected && checkComment(e, "outside");
              }}>
              <FormGroup id={`comment_checkbox_${commentIndex}`}>
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      sx={{
                        height: "19.5px !important",
                        width: "19.5px !important",
                        "& .MuiSvgIcon-root": { fontSize: "20px !important" },
                      }}
                      checked={commentSelected}
                    />
                  }
                />
              </FormGroup>
              <span className="comment_title_single" id={`comment_title_${commentIndex}`}>
                {/* sreeenath condition */}
                {/* {(prompt == null || prompt == "") &&
                    (recommendation?.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ") == "" || recommendation == null) ?
                    'Untitled'
                    : (prompt == null || prompt == "") ? 
                    recommendation?.replace(/<\/?[^>]+(>|$)/g, "")?.length > 250 ? 
                    `${recommendation?.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ")?.substring(250, 0)} ...`:recommendation?.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ")
                      : prompt?.length > 120 ?`${prompt} ...`:prompt } */}

                {/* anil condition */}
                {/* blank condition  recommendation?.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ")== "" */}
                {/* {console.log("recommendation:",(recommendation?.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ").trim())?.length)} */}
                {is_mannual_prompt !== undefined
                  ? is_mannual_prompt === 0
                    ? prompt
                      ? prompt
                      : recommendation === null ||
                        recommendation
                          ?.replace(/<\/?[^>]+(>|$)/g, "")
                          ?.replace(/&nbsp;/g, " ")
                          .trim()?.length === 0
                      ? "Untitled"
                      : recommendation?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                    : prompt === null || prompt === "" || prompt === undefined
                    ? recommendation === null ||
                      recommendation
                        ?.replace(/<\/?[^>]+(>|$)/g, "")
                        ?.replace(/&nbsp;/g, " ")
                        .trim()?.length === 0
                      ? "Untitled"
                      : recommendation?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                    : prompt?.length > 120
                    ? `${prompt} ...`
                    : prompt
                  : prompt === null || prompt === "" || prompt === undefined
                  ? recommendation === null ||
                    recommendation
                      ?.replace(/<\/?[^>]+(>|$)/g, "")
                      ?.replace(/&nbsp;/g, " ")
                      .trim()?.length === 0
                    ? "Untitled"
                    : recommendation?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                  : prompt?.length > 120
                  ? `${prompt} ...`
                  : prompt}

                {/* =================== */}
                {/* { (prompt==null || prompt=='') ? (recommendation==null || recommendation?.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ").trim()?.length === 0 ) ?"Untitled": recommendation?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                      : prompt?.length > 120?`${prompt} ...`:prompt } */}
                {/* ======================= */}
                {/* {prompt !== null ? prompt:recommendation!== null && recommendation !== undefined && recommendation !== '' ?parse(recommendation)?.length > 250? parse(recommendation)?.substring(250, 0):parse(recommendation):''} */}
              </span>
            </span>
            {commentSelected == 1 && (
              <span
                className="details_action"
                id={`edit_comment_${commentIndex}`}
                onClick={() => {
                  setShowCommentModal(true);
                  setEditComment(true);
                  setDisableCommentBtn(false);
                }}>
                <EditIcon />
                Edit
              </span>
            )}
          </Grid>
          <Grid style={{ overflowY: "hidden" }}>
            <Grid
              className="comment_detail_title"
              onClick={(e) => {
                checkComment(e, "outside");
              }}>
              <span className="comment_description">
                {is_mannual_prompt !== undefined && is_mannual_prompt === 0 && !recommendation
                  ? ""
                  : prompt === null || prompt === "" || prompt === undefined
                  ? ""
                  : recommendation !== null && recommendation !== undefined && recommendation !== ""
                  ? recommendation?.replace(/<\/?[^>]+(>|$)/g, "")?.length > 250
                    ? `${recommendation?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")} `
                    : recommendation?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                  : ""}
              </span>
            </Grid>
          </Grid>

          {!commentSelected && (
            <div
              style={{
                display: "flex",
                marginLeft: "18px",
                gap: "8px",
                flexWrap: "wrap",
                padding: "9px 13px",
              }}>
              {ratingDataCheck?.data?.length !== 0 && ratingDataCheck !== null && ratingDataCheck !== undefined && ratingDataCheck?.data !== null ? (
                props?.comment_input_controls
                  ?.filter((comment) => comment.type == "Rating")[0]
                  ?.data?.map((rating) => (
                    <span className="rating_outside_square_container" key={rating.Id}>
                      <span className="rating_outside_square" style={{ background: `${rating.Color}` }}>
                        {rating.include_in_summary == "1" && <FlagIcon />}
                      </span>
                      <span className="rating_name_outside_text">{rating.Name}</span>
                    </span>
                  ))
              ) : (
                <>
                  {" "}
                  <span className="rating_outside_square_outSide"></span>
                  <span>No rating </span>
                </>
              )}
            </div>
          )}
          <Collapse in={commentSelected}>
            {/* ==========Rating===================== */}
            <Grid container sx={{ mt: "17px" }}>
              <CommentRating
                data={comment_input_controls?.find((data) => data.type === "Rating")}
                title="Rating"
                action="comment"
                templateId={templateId}
                categoryId={categoryId}
                lineUuid={lineUuid}
                commentUuid={uuid}
                commentIndex={commentIndex}
              />
            </Grid>

            {/* ==========Location===================== */}
            <Grid container sx={{ mt: "32px" }}>
              <Location
                data={comment_input_controls?.find((data) => data.type === "Location")}
                title="Location"
                action="comment"
                templateId={templateId}
                categoryId={categoryId}
                lineUuid={lineUuid}
                type="comment"
                commentUuid={uuid}
                commentIndex={commentIndex}
              />
            </Grid>
            {/* ==========media===================== */}
            <Grid container sx={{ mt: "32px" }}>
              <Media
                data={comment_input_controls?.find((data) => data.type === "Media")}
                title="Add Media"
                action="comment"
                toggle={toggle}
                templateId={templateId}
                categoryId={categoryId}
                categoryUuid={categoryUuid}
                lineUuid={lineUuid}
                commentUuid={uuid}
                setSelectedImages={setSelectedImages}
                selectedMediaFiles={selectedMediaFiles}
                setSelectedMediaFiles={setSelectedMediaFiles}
                width={setWidth}
                setWidth={setWidth}
                height={height}
                setHeight={setHeight}
                commentIndex={commentIndex}
              />
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
};

export default CommentDetails;
