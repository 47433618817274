import { Grid } from "@mui/material";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import { useDispatch, useSelector } from "react-redux";
import "../../../Assets/Styles/Containers/_button.scss";
import React, { useState, useEffect, useRef } from "react";
import "../../../Assets/Styles/Containers/_inspection.scss";
import { UpdateTemplateDataSpecific } from "../../Helpers/ApiCall";
import { convertFontToSpan, filterObjectsUptoCategory } from "../../Helpers/HelperFunctions";
import FroalaEditorComponent from "../../Common/HtmlEditor/FroalaEditorComponent";
import { filterSelectTagIntro } from "../LineDetails/CommentDetails/CommentLibraryHelper";

const froalaConfig = {
  imagePaste: false,
  spellCheck: true,
  heightMin: 201,
  heightMax: 300,
  toolbarButtons: {
    moreText: {
      buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"],
      align: "left",
      buttonsVisible: 3,
    },
    moreRich: { buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"], align: "left", buttonsVisible: 0 },
    moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
  },
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

const AddIntroText = (props) => {
  const editorRef = useRef();
  const containerRef = useRef();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const { categoryData, templateId, categoryId } = props;
  const [editorContent, setEditorContent] = useState({
    innerHTML: categoryData?.introductory_text === "" ? "<p></p>" : categoryData?.introductory_text.replace(/<([a-zA-Z0-9]+)>\s*<\/\1>\s*/g, ""),
  });
  const [introductoryDropdowns, setIntroductoryDropdowns] = useState(categoryData?.introductory_text_dropdowns || []);
  const [inspectionResponse, setInspectionResponse] = useState();
  const [isCopy, setIsCopy] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  let templateIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
  let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
  let prevData = inspectionResponse?.inspectionData?.data;

  const handleChange = (name, content) => {
    setEditorContent({ innerHTML: content.replace(/<([a-zA-Z0-9]+)>\s*<\/\1>\s*/g, "") });
    setIsCopy(false);
    setHasChanges(true);
    setIntroductoryDropdowns(introductoryDropdowns?.length ? introductoryDropdowns : categoryData?.introductory_text_dropdowns || []);
  };

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData);
    }
  }, [storeData.inspectionData]);

  useEffect(() => {
    setEditorContent({
      innerHTML: categoryData?.introductory_text === "" ? "<p></p>" : categoryData?.introductory_text.replace(/<([a-zA-Z0-9]+)>\s*<\/\1>\s*/g, ""),
    });
  }, [categoryData]);

  const saveIntroText = async () => {
    if (!hasChanges) return;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].introductory_text = filterSelectTagIntro(editorContent?.innerHTML);
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoCategory(prevData, templateIndex, categoryIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setHasChanges(false);
    setIsCopy(true);
  };

  const handleChangeDropdown = async (event, dropdownUuid) => {
    const selectedValue = event.target.value;
    let textDropdowns = prevData.inspectiontemplates[templateIndex].categories[categoryIndex].introductory_text_dropdowns;
    let textDropdownIndex = prevData.inspectiontemplates[templateIndex].categories[categoryIndex].introductory_text_dropdowns.findIndex(
      (dropdown) => dropdown.dropdown_uuid === dropdownUuid
    );
    textDropdowns[textDropdownIndex] = { ...textDropdowns[textDropdownIndex], selected_value: selectedValue };
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].introductory_text_dropdowns = textDropdowns;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoCategory(prevData, templateIndex, categoryIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setHasChanges(true);
  };

  let introDropElements = document.querySelectorAll(".intro-cl-dropdown");
  introDropElements.forEach((element, index) => {
    if (element) {
      element.onchange = (event) => handleChangeDropdown(event, element.id, index);
    }
  });

  // Handle unsaved changes warning on page refresh or tab close
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasChanges) {
        event.preventDefault();
        event.returnValue = ""; // Chrome requires returnValue to be set
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        saveIntroText();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hasChanges, editorContent, introductoryDropdowns]);

  return (
    <Grid container className="input-controll" ref={containerRef}>
      <Grid item xs={12} sm={12} sx={{ padding: "8px 16px 16px 16px" }}>
        <FroalaEditorComponent
          copy={isCopy}
          showAI={false}
          commentData={categoryData}
          editorData={convertFontToSpan(editorContent)}
          editor={editorRef}
          id={"intro-text-editor"}
          onChange={handleChange}
          froalaConfig={froalaConfig}
        />
      </Grid>
    </Grid>
  );
};

export default AddIntroText;
