import Compressor from "compressorjs";
import { GetUTCTime } from "./GetUTCTime";

export const totalLineCountCalulate = (inspTemplates) => {
  let overallLinesPresent = 0;
  let overallLinesCompleted = 0;
  let overallPrecent = 0;
  inspTemplates?.forEach((template, index) => {
    template?.categories?.forEach((category) => {
      if (index !== 0 && category.is_inspection_details === 1) {
        overallLinesPresent += 0;
        overallLinesCompleted += 0;
      } else {
        let { lineComplete, totalLines } = categoryPercentCalculate(category);
        overallLinesPresent += totalLines;
        overallLinesCompleted += lineComplete;
      }
    });
  });
  overallPrecent = (overallLinesCompleted / overallLinesPresent) * 100;
  return { overallLinesPresent, overallLinesCompleted, overallPrecent };
};
export const totalLineCountCalulateForReview = (inspTemplates) => {
  let overallLinesPresent = 0;
  let overallLinesCompleted = 0;
  let overallPrecent = 0;
  inspTemplates?.forEach((template) => {
    template?.categories?.forEach((category) => {
      if (category.is_inspection_details === 1) {
        overallLinesPresent += 0;
        overallLinesCompleted += 0;
      } else {
        let { lineComplete, totalLines } = categoryPercentCalculate(category);
        overallLinesPresent += totalLines;
        overallLinesCompleted += lineComplete;
      }
    });
  });
  overallPrecent = (overallLinesCompleted / overallLinesPresent) * 100;
  return { overallLinesPresent, overallLinesCompleted, overallPrecent };
};
export const categoryPercentCalculate = (category) => {
  if (category?.lines === null || category?.lines?.length === 0) return { percentage: 0, lineComplete: 0, totalLines: 0 };
  else {
    let totalLines = 0;
    let lineComplete = 0;
    let percentage = 0;
    category.lines
      ?.filter(
        (itm) => itm?.is_deleted !== 1
        // && itm.optional !==1
      )
      .forEach((line) => {
        var isLineInputIncomplete = false;
        totalLines = totalLines + 1;
        if (line && line.line_input_controls?.length) {
          for (let i = 0; i < line.line_input_controls?.length; i += 1) {
            if (line.line_input_controls[i]?.type === "Media" && line.line_input_controls[i]?.is_disabled != 1) {
              if (line?.line_input_controls[i]?.data?.filter((itm) => itm?.is_deleted !== 1)?.length > 0) {
                isLineInputIncomplete = true;
              }
            }
            // if (line.line_input_controls[i]?.type === "Information") {
            //   if (line.line_input_controls[i].data?.length > 0 && line.line_input_controls[i]?.data[0] !== '<p><br></p>' && line.line_input_controls[i]?.data !== '<p><br></p>') {
            //     isLineInputIncomplete = true;
            //   }
            // }
            if (line.line_input_controls[i]?.type === "Nameplate") {
              if (
                line?.line_input_controls[i]?.data?.modelNumber?.length > 0 ||
                line?.line_input_controls[i]?.data?.serialNumber?.length > 0 ||
                (line?.line_input_controls[i]?.data?.image !== null && line?.line_input_controls[i]?.data?.image !== undefined && line?.line_input_controls[i]?.data?.image.is_deleted !== 1)
              ) {
                isLineInputIncomplete = true;
              }
            }
            if (category.is_inspection_details !== 1) {
              if (line.line_input_controls[i]?.type === "Description") {
                if (line.line_input_controls[i].data?.length > 0 && line.line_input_controls[i]?.is_disabled != 1) {
                  isLineInputIncomplete = true;
                }
              }
              if (line.line_input_controls[i]?.type === "Additional Input") {
                if (line.line_input_controls[i].data?.length > 0) {
                  isLineInputIncomplete = true;
                }
              }
            }
            if (line.line_input_controls[i].type === "Location") {
              if (line.line_input_controls[i].data?.length > 0 && line.line_input_controls[i].data[0] !== null && line.line_input_controls[i]?.is_disabled != 1) {
                isLineInputIncomplete = true;
              }
            }
            if (line.line_input_controls[i].type === "CheckBox") {
              if (line.line_input_controls[i].data?.length > 0) {
                isLineInputIncomplete = true;
              }
            }

            if (line.line_input_controls[i].type === "Temperature") {
              if (line.line_input_controls[i].data?.value !== null && line.line_input_controls[i].data?.value !== "" && line.line_input_controls[i].data?.length !== 0) {
                isLineInputIncomplete = true;
              }
            }

            if (line.line_input_controls[i].type === "TextBox") {
              if (line.line_input_controls[i].data?.length > 0) {
                isLineInputIncomplete = true;
              }
            }
          }
        }

        if (line && line?.comments?.length) {
          line.comments?.forEach((itm) => {
            if (itm.is_selected === 1 && itm.is_deleted !== 1) {
              isLineInputIncomplete = true;
            }
            if (itm.tags?.length > 0 && itm.is_deleted !== 1) {
              isLineInputIncomplete = true;
            }
          });
        }

        if (isLineInputIncomplete) {
          lineComplete = lineComplete + 1;
        }
      });

    percentage = (lineComplete / totalLines) * 100;
    return { percentage, lineComplete, totalLines };
  }
};

// export const categoryPercentCalculate=(category)=>{
//   return Math.random() *100
// }
// check for selected category on add category
export const checkCategoryPresent = (category, selected) => {
  let selectStatus = selected.map((selectedCat) => {
    if (selectedCat.uuid === category.uuid) return true;
    else return false;
  });
  return selectStatus.some((el) => el === true);
};

export const checkInspecLineComplete = (line) => {
  var checkInspecLineinput4Incomplete = false;
  if (line && line.line_input_controls.length) {
    for (let i = 0; i < line.line_input_controls.length; i += 1) {
      if (line.line_input_controls[i].type === "CheckBox") {
        if (line.line_input_controls[i].data?.length > 0) {
          checkInspecLineinput4Incomplete = true;
        }
      }
      if (line.line_input_controls[i].type === "Description") {
        if (line.line_input_controls[i].data?.length < 0) {
          checkInspecLineinput4Incomplete = true;
        }
      }
      if (line.line_input_controls[i].type === "Temperature") {
        if (line.line_input_controls[i].data?.value !== null && line.line_input_controls[i].data?.value !== "" && line.line_input_controls[i].data?.length !== 0) {
          checkInspecLineinput4Incomplete = true;
        }
      }
      if (line.line_input_controls[i].type === "TextBox") {
        if (line.line_input_controls[i].data?.length > 0) {
          checkInspecLineinput4Incomplete = true;
        }
      }
    }
  }
  return checkInspecLineinput4Incomplete;
};

export const checkLineComplete = (line, type) => {
  var isLineInputIncomplete = false;
  if (line && line.line_input_controls.length) {
    for (let i = 0; i < line.line_input_controls.length; i += 1) {
      if (line.line_input_controls[i].type === "Media") {
        if (line?.line_input_controls[i]?.data?.filter((itm) => itm?.is_deleted !== 1)?.length > 0) {
          isLineInputIncomplete = true;
        }
      }
      if (line.line_input_controls[i].type === "Nameplate") {
        if (
          line?.line_input_controls[i]?.data?.modelNumber?.length > 0 ||
          line?.line_input_controls[i]?.data?.serialNumber?.length > 0 ||
          (line?.line_input_controls[i]?.data?.image !== null && line?.line_input_controls[i]?.data?.image !== undefined && line?.line_input_controls[i]?.data?.image.is_deleted !== 1)
        ) {
          isLineInputIncomplete = true;
        }
      }
      if (type !== "inspectionDetails") {
        if (line.line_input_controls[i].type === "Description" && line.line_input_controls[i]?.is_disabled != 1) {
          if (line.line_input_controls[i].data?.length > 0) {
            isLineInputIncomplete = true;
          }
        }
        if (line.line_input_controls[i].type === "Additional Input") {
          if (line.line_input_controls[i].data?.length > 0) {
            isLineInputIncomplete = true;
          }
        }
      }

      if (line.line_input_controls[i].type === "Location") {
        if (line.line_input_controls[i].data?.length > 0 && line.line_input_controls[i].data[0] !== null && line.line_input_controls[i]?.is_disabled != 1) {
          isLineInputIncomplete = true;
        }
      }
      if (line.line_input_controls[i].type === "CheckBox") {
        if (line.line_input_controls[i].data?.length > 0) {
          isLineInputIncomplete = true;
        }
      }

      if (line.line_input_controls[i].type === "Temperature") {
        if (line.line_input_controls[i].data?.value !== null && line.line_input_controls[i].data?.value !== "" && line.line_input_controls[i].data?.length !== 0) {
          isLineInputIncomplete = true;
        }
      }

      if (line.line_input_controls[i].type === "TextBox") {
        if (line.line_input_controls[i].data?.length > 0) {
          isLineInputIncomplete = true;
        }
      }
    }
  }
  if (line && line.comments.length) {
    line.comments?.forEach((itm) => {
      if (itm.is_selected === 1 && itm.is_deleted !== 1) {
        isLineInputIncomplete = true;
      }
      if (itm.tags?.length > 0 && itm.is_deleted !== 1) {
        isLineInputIncomplete = true;
      }
    });
  }
  return isLineInputIncomplete;
};

export const RatingCheck = (item, isNarrative) => {
  let mediasIndexValue = item?.line_input_controls?.findIndex((itm) => itm.type === "Media");

  let commentArr = item && item.comments;
  var Rating = [];
  var imagesData = [];
  var videosData = [];
  if (!isNarrative) {
    if (commentArr && commentArr.length) {
      commentArr.forEach((commentItem) => {
        if (commentItem && commentItem?.comment_input_controls?.length && commentItem?.is_selected === 1) {
          for (let i = 0; i < commentItem.comment_input_controls.length; i += 1) {
            if (commentItem.comment_input_controls[i].type === "Rating") {
              let comData = commentItem.comment_input_controls[i].data;
              Rating = Rating.concat(comData);
            } else if (commentItem.comment_input_controls[i].type === "Media") {
              commentItem.comment_input_controls[i].data
                ?.filter((itm) => itm?.is_deleted !== 1)
                .forEach((item) => {
                  if (item.filetype === "image") {
                    imagesData.push(item);
                  } else if (item.filetype === "video") {
                    videosData.push(item);
                  }
                });
            }
          }
        }
      });
      // setRatings(Rating);
    }
  }

  if (mediasIndexValue >= 0) {
    let mediaData = item?.line_input_controls[mediasIndexValue]?.data;

    mediaData
      ?.filter((itm) => itm?.is_deleted !== 1)
      .forEach((item) => {
        if (item.filetype === "image") {
          imagesData.push(item);
        }
        if (item.filetype === "video") {
          videosData.push(item);
        }
      });
  }
  // setImages(imagesData);
  // setVideos(videosData);
  return Rating;
};

export const mediaCheck = (item, isNarrative) => {
  let mediasIndexValue = item?.line_input_controls?.findIndex((itm) => itm.type === "Media" && itm?.is_disabled != 1);

  let commentArr = item && item?.comments;
  var Rating = [];
  var imagesData = [];
  var videosData = [];
  if (commentArr && commentArr?.length !== 0) {
    commentArr.forEach((commentItem) => {
      if (commentItem && commentItem?.comment_input_controls?.length && commentItem?.is_selected === 1) {
        for (let i = 0; i < commentItem.comment_input_controls.length; i += 1) {
          if (commentItem.comment_input_controls[i].type === "Rating") {
            let comData = commentItem.comment_input_controls[i].data;
            Rating = Rating.concat(comData);
          } else if (commentItem.comment_input_controls[i].type === "Media" && commentItem.comment_input_controls[i]?.is_disabled !== 1) {
            commentItem.comment_input_controls[i].data
              ?.filter((itm) => itm?.is_deleted !== 1)
              .forEach((item) => {
                if (item.filetype === "image") {
                  imagesData.push(item);
                } else if (item.filetype === "video") {
                  videosData.push(item);
                }
              });
          }
        }
      }
    });
    // setRatings(Rating);
  }

  if (mediasIndexValue >= 0) {
    let mediaData = item?.line_input_controls[mediasIndexValue]?.data;

    mediaData
      ?.filter((itm) => itm?.is_deleted !== 1)
      .forEach((item) => {
        if (item.filetype === "image") {
          imagesData.push(item);
        }
        if (item.filetype === "video") {
          videosData.push(item);
        }
      });
  }
  // setImages(imagesData);
  // setVideos(videosData);
  return imagesData;
};

// comment count
export const narrativeCommentCount = (item, isNarrative) => {
  let comments = item?.comments?.filter((comment) => comment?.is_selected === 1 && comment.is_deleted !== 1);
  return isNarrative ? comments : [];
};
// comment count

export const narrativeRatingCheck = (item, isNarrative) => {
  let commentArr = item && item?.comments;
  var Rating = [];
  if (isNarrative) {
    if (commentArr && commentArr?.length) {
      commentArr.forEach((commentItem) => {
        if (commentItem && commentItem?.summaries?.length > 0 && commentItem?.is_selected === 1 && commentItem.is_deleted !== 1) {
          let summaryData = commentItem.summaries;
          Rating = Rating?.concat(summaryData);
        }
      });
    }
  }
  return Rating;
};

export const videoCheck = (item, isNarrative) => {
  let mediasIndexValue = item?.line_input_controls?.findIndex((itm) => itm.type === "Media");

  let commentArr = item && item.comments;
  var Rating = [];
  var imagesData = [];
  var videosData = [];
  if (commentArr && commentArr?.length) {
    commentArr.forEach((commentItem) => {
      if (commentItem && commentItem?.comment_input_controls?.length && commentItem?.is_selected === 1) {
        for (let i = 0; i < commentItem.comment_input_controls.length; i += 1) {
          if (commentItem.comment_input_controls[i].type === "Rating") {
            let comData = commentItem.comment_input_controls[i].data;
            Rating = Rating.concat(comData);
          } else if (commentItem.comment_input_controls[i].type === "Media") {
            commentItem.comment_input_controls[i].data
              ?.filter((itm) => itm?.is_deleted !== 1)
              .forEach((item) => {
                if (item.filetype === "image") {
                  imagesData.push(item);
                } else if (item.filetype === "video" && item.online === "online") {
                  videosData.push(item);
                }
              });
          }
        }
      }
    });
    // setRatings(Rating);
  }

  if (mediasIndexValue >= 0) {
    let mediaData = item?.line_input_controls[mediasIndexValue]?.data;

    mediaData
      ?.filter((itm) => itm?.is_deleted !== 1)
      .forEach((item) => {
        if (item.filetype === "image") {
          imagesData.push(item);
        }
        if (item.filetype === "video" && item.online === "online") {
          videosData.push(item);
        }
      });
  }
  // setImages(imagesData);
  // setVideos(videosData);
  return videosData;
};

// find selected comment counts
export const commentCount = (comments) => {
  let comntCount = 0;
  comments?.forEach((comment) => {
    comment.is_selected == 1 && comntCount++;
  });
  return comntCount;
};

export const separtesummeryCounter = (rating, template) => {
  const summaryName = rating
    ?.filter((item) => item?.include_in_summary == 1)
    ?.map((item) => {
      return { name: item?.Name, count: 0 };
    });
  // inspectionResponse?.map((template) =>
  template?.categories?.map((category) =>
    category?.lines?.map((lines) =>
      lines?.comments?.map((comment) => {
        if (comment?.is_selected) {
          comment?.comment_input_controls
            ?.filter((item) => item?.type === "Rating")[0]
            ?.data?.map((el) => {
              summaryName.map((names, idx) => {
                if (el.Name == names.name) {
                  summaryName[idx]["count"] = summaryName[idx].count + 1;
                }
              });
            });
        }
      })
    )
  );
  // );
  return JSON.stringify(summaryName);
};

export const summeryItemCount = (summeryRating, inspectionResponse) => {
  const summaryName = summeryRating.map((item) => {
    return { name: item?.Name, count: 0 };
  });
  inspectionResponse?.map((template) =>
    template?.categories?.map((category) =>
      category?.lines?.map((lines) =>
        lines?.comments?.map((comment) => {
          if (comment?.is_selected) {
            comment?.comment_input_controls
              ?.filter((item) => item?.type === "Rating")[0]
              ?.data?.map((el) => {
                summaryName.map((names, idx) => {
                  if (el.Name == names.name) {
                    summaryName[idx]["count"] = summaryName[idx].count + 1;
                  }
                });
              });
          }
        })
      )
    )
  );
  return JSON.stringify(summaryName);
};

export const totalCommentCounter = (inspectionResponse) => {
  let ratingcount = 0;
  let noRatingCount = noRatingCounter(inspectionResponse);
  inspectionResponse?.map((template) =>
    template?.categories?.map((category) =>
      category?.lines?.map((lines) =>
        lines?.comments?.map((comment) => {
          if (comment?.is_selected) {
            comment?.comment_input_controls?.filter((item) => item?.type == "Rating")[0]?.data?.length >= 1 && (ratingcount += 1);
          }
        })
      )
    )
  );
  return ratingcount + noRatingCount;
};

export const commentInputCounter = (rating, template) => {
  let commentCount = 0;
  // inspectionResponse?.map((template) =>
  template?.categories?.map((category) =>
    category?.lines?.map((lines) =>
      lines?.comments?.map((comment) => {
        if (comment?.is_selected) {
          comment?.comment_input_controls?.filter((item) => item?.type === "Rating")[0]?.data?.map((el) => el?.Name === rating?.Name && commentCount++);
        }
      })
    )
  );
  // );
  return commentCount;
};

export const noRatingCounter = (template, isNarrative) => {
  let noRatingCount = 0;
  let noRatingCountWithNoCommentInput = 0;
  if (!isNarrative) {
    template?.categories?.map((category) =>
      category?.lines?.map((lines) =>
        lines?.comments?.map((comment) => {
          if (comment?.is_selected) {
            // if(!comment?.comment_input_controls.length){noRatingCountWithNoCommentInput++}
            if (
              ((comment?.comment_input_controls.filter((item) => item?.type === "Rating")[0]?.data?.length === 0 ||
                comment?.comment_input_controls.filter((item) => item?.type === "Rating")[0]?.data?.length === undefined) &&
                comment?.comment_input_controls.filter((item) => item?.type === "Media")[0]?.data?.length !== 0) ||
              ((comment?.comment_input_controls.filter((item) => item?.type === "Rating")[0]?.data?.length === 0 ||
                comment?.comment_input_controls.filter((item) => item?.type === "Rating")[0]?.data?.length === undefined) &&
                comment?.comment_input_controls.filter((item) => item?.type === "Media")[0]?.data?.length === 0)
            )
              noRatingCount++;
          }
        })
      )
    );
  }
  return noRatingCount + noRatingCountWithNoCommentInput;
};

export function splitDate(date) {
  var result = [];
  var counter = 0;
  for (var i = date.length - 1; i >= 0; i -= 1) {
    result[counter] = date[i];
    counter += 1;
  }
  var date = result[1]?.replace(/-/g, "/");
  const tConvert = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };
  var time = tConvert(result[0].slice(0, -3));
  return { date, time };
}
export const checkCategoryComplete = (category) => {
  if (category?.lines === null || category?.lines === undefined || category?.lines.length === 0) {
    return true;
  } else {
    let catStatusArray = category?.lines?.map((line) => checkLineComplete(line));
    return catStatusArray?.every((el) => el === true);
  }
};
export const checkTemplateComplete = (template) => {
  if (template?.categories === null || template?.categories === undefined || template?.categories?.length === 0) {
    return true;
  } else {
    let tempStatusArray = template?.categories?.filter((el) => el.is_inspection_details !== 1)?.map((category) => checkCategoryComplete(category));
    return tempStatusArray?.every((el) => el === true);
  }
};

export const categoryMediaCount = (category) => {
  let categoryImageCount = 0;
  let categoryVideoCount = 0;
  category.lines?.forEach((line) => {
    let lineImageLength = mediaCheck(line)?.length;
    let lineVideoLength = videoCheck(line)?.length;
    categoryImageCount += lineImageLength;
    categoryVideoCount += lineVideoLength;
  });
  return { categoryImageCount, categoryVideoCount };
};

export const pdfMediaCount = (category) => {
  let categoryImageCount = 0;
  let categoryVideoCount = 0;
  categoryImageCount = category?.value?.filter((item) => item.filetype == "image" && item.is_deleted !== 1)?.length;
  categoryVideoCount = category?.value?.filter((item) => item.filetype == "video" && item.is_deleted !== 1)?.length;
  return { categoryImageCount, categoryVideoCount };
};

export const templateMediaCount = (template) => {
  let pdfCatgoryData = template?.pdf_data?.filter((category) => category.type === "MediaUploader");
  let templateImageCount = 0;
  let templateVideoCount = 0;
  if (template?.is_pdf_template !== 1) {
    template?.categories?.forEach((category) => {
      let { categoryImageCount, categoryVideoCount } = categoryMediaCount(category);
      templateImageCount += categoryImageCount;
      templateVideoCount += categoryVideoCount;
    });
  } else {
    pdfCatgoryData?.forEach((category) => {
      let { categoryImageCount, categoryVideoCount } = pdfMediaCount(category);
      templateImageCount += categoryImageCount;
      templateVideoCount += categoryVideoCount;
    });
  }
  return { templateImageCount, templateVideoCount };
};

export const totalMediaCount = (inspTemplates) => {
  let totalImageCount = 0;
  let totalVideoCount = 0;
  inspTemplates?.forEach((template) => {
    let { templateImageCount, templateVideoCount } = templateMediaCount(template);
    totalImageCount += templateImageCount;
    totalVideoCount += templateVideoCount;
  });
  return { totalImageCount, totalVideoCount };
};

export const templateMediaCheck = (template) => {
  let { templateImageCount, templateVideoCount } = templateMediaCount(template);
  if (templateImageCount + templateVideoCount === 0) return false;
  else return true;
};

export const categoryMediaCheck = (category, ...args) => {
  const [media_type] = args;
  let categoryMediaStatus = category?.lines?.map((line) => {
    if (media_type == "image" && mediaCheck(line)?.length > 0) {
      return true;
    } else if (media_type == "video" && videoCheck(line)?.length > 0) {
      return true;
    } else if (media_type == "all" && (mediaCheck(line)?.length > 0 || videoCheck(line)?.length > 0)) {
      return true;
    } else return false;
  });
  return categoryMediaStatus?.some((el) => el === true);
};

export const getImageDimensions = (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      resolve({ imgWidth, imgHeight });
    };
    img.src = URL.createObjectURL(file);
  });
};

export const resizeImage = (file, width, height, quality) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      width,
      height,
      quality, // Set the image compression quality
      success(result) {
        // Resolves the Promise with the resized image data
        resolve(result);
      },
      error(err) {
        // Rejects the Promise with an error if there's an issue
        reject(err);
      },
    });
  });
};

export function getMergeIds(obj) {
  let mergeIds = [];
  function traverseElements(elements) {
    for (const element of elements) {
      if (element.merge_id) {
        mergeIds.push(element.merge_id);
      }
      if (element.elements && element.elements.length > 0) {
        traverseElements(element.elements);
      }
    }
  }

  if (obj.elements && obj.elements.length > 0) {
    traverseElements(obj.elements);
  }
  return mergeIds;
}

export async function addCheckFlagToPdfData(pdfData, mergeIdsArray, checked) {
  const updatedPdfData = await Promise.all(
    pdfData.map(async (item) => {
      const updatedAt = await GetUTCTime();
      const checkFlag = checked && mergeIdsArray.includes(item.merge_id);
      return {
        ...item,
        show_in_pdf: checkFlag ? 1 : 0,
        updated_at: updatedAt,
      };
    })
  );
  const filteredPdfData = updatedPdfData.filter((item) => mergeIdsArray.includes(item.merge_id));
  return filteredPdfData;
}
export const checkDescriptionDisabled = (line) => {
  let descriptionData = line?.line_input_controls?.filter((lineData) => lineData.type === "Description");
  if (descriptionData[0]?.is_disabled === 1) return true;
  else return false;
};

export function filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex) {
  let filteredData = JSON.parse(JSON.stringify(prevData));
  let filteredTemplateObject = filteredData?.inspectiontemplates[templateIndex];
  let filteredCategoryObject = filteredTemplateObject?.categories[categoryIndex];
  let filteredLineObject = filteredCategoryObject?.lines[lineIndex];
  filteredCategoryObject.lines = [filteredLineObject];
  filteredTemplateObject.categories = [filteredCategoryObject];
  filteredData.inspectiontemplates = [filteredTemplateObject];
  return filteredData;
}

export function filterObjectsUptoCategory(prevData, templateIndex, categoryIndex) {
  let filteredData = JSON.parse(JSON.stringify(prevData));
  let filteredTemplateObject = filteredData?.inspectiontemplates[templateIndex];
  let filteredCategoryObject = filteredTemplateObject?.categories[categoryIndex];
  filteredTemplateObject.categories = [filteredCategoryObject];
  filteredData.inspectiontemplates = [filteredTemplateObject];
  return filteredData;
}
export function filterObjectsUptoTemplate(prevData, templateIndex) {
  let filteredData = JSON.parse(JSON.stringify(prevData));
  let filteredTemplateObject = filteredData?.inspectiontemplates[templateIndex];
  filteredData.inspectiontemplates = [filteredTemplateObject];
  return filteredData;
}

export function filterPdfTemplateData(prevData, templateIndex, filteredPDFData, mergeId) {
  let filteredData = JSON.parse(JSON.stringify(prevData));
  let filteredTemplateObject = filteredData?.inspectiontemplates[templateIndex];
  filteredTemplateObject.pdf_data = filteredPDFData;
  let pdfDataFilteredWithmergeID = filteredTemplateObject.pdf_data.filter((element) => element.merge_id === mergeId);
  filteredTemplateObject.pdf_data = pdfDataFilteredWithmergeID;
  filteredTemplateObject.pdf_categories = [];
  filteredData.inspectiontemplates = [filteredTemplateObject];
  return filteredData;
}
export const getNonNarrativeTemplatesGuid = (templates) => {
  let nonNarrativeTemplatesGuid = templates
    ?.map((template) => {
      if (template.is_narrative_template !== 1 && template.is_pdf_template !== 1) return template.template_guid;
    })
    .filter((item) => item !== undefined);
  return nonNarrativeTemplatesGuid;
};

export function convertFontToSpan(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString.innerHTML, "text/html");
  const sizeToPx = { 1: "10px", 2: "12px", 3: "14px", 4: "16px", 5: "18px", 6: "20px", 7: "22px" };
  function replaceFontWithSpan(element) {
    element.querySelectorAll("font").forEach((font) => {
      const fontSize = sizeToPx[font.getAttribute("size")] || "14px";
      const fontColor = font.getAttribute("color");
      const span = document.createElement("span");
      span.style.fontSize = fontSize;
      if (fontColor) span.style.color = fontColor;
      span.innerHTML = font.innerHTML;
      replaceFontWithSpan(span);
      font.replaceWith(span);
    });
  }
  replaceFontWithSpan(doc.body);
  return { innerHTML: doc.body.innerHTML };
}
