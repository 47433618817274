import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "../../../../Assets/Styles/Containers/_button.scss";
import "../../../../Assets/Styles/Containers/_inspection.scss";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import CloseIcon from "@mui/icons-material/Close";
import { convertFontToSpan, filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";
import FroalaWithoutDropdown from "../../../Common/HtmlEditor/FroalaWithoutDropdown";

const froalaConfig = {
  imagePaste: false,
  spellCheck: true,
  heightMin: 201,
  heightMax: 300,
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

const toolbarButtonsConfig = {
  moreText: {
    buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"],
    align: "left",
    buttonsVisible: 3,
  },
  moreRich: {
    buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"],
    align: "left",
    buttonsVisible: 0,
  },
  moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
};

const AddInformation = ({ showInformationModal, setShowInformationModal, information, setInformation, templateId, categoryId, lineUuid, inputChanged, setInputChanged }) => {
  const [editorContent, setEditorContent] = useState({ innerHTML: "" });
  const [inspectionResponse, setInspectionResponse] = useState();
  const [isCopy, setIsCopy] = useState(true);
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();
  const editorRef = useRef();

  const handleClose = () => {
    setInputChanged(false);
    setShowInformationModal(false);
    setEditorContent("");
  };

  const handleChange = (name, content) => {
    setInputChanged(content !== editorContent?.innerHTML);
    setEditorContent({ innerHTML: content.replace(/<([a-zA-Z0-9]+)>\s*<\/\1>\s*/g, "") });
    setIsCopy(false);
  };

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData);
      const converText = information !== undefined && information?.length > 0 ? information.toString() : "";
      setEditorContent({ innerHTML: converText });
    }
  }, [storeData.inspectionData, information, showInformationModal]);

  const saveInformation = async () => {
    let templateIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let linIpIdx = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(
      (lineInpt) => lineInpt.type === "Information"
    );
    let prevData = JSON.parse(JSON.stringify(inspectionResponse?.inspectionData?.data));
    delete prevData?.report_media_library_files;
    if (linIpIdx !== -1) {
      prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[linIpIdx].data = [
        editorContent?.innerHTML === "" ? "<p><br></p>" : editorContent?.innerHTML,
      ];
      prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[linIpIdx].updated_at = await GetUTCTime();
    } else {
      let informationObject = {
        line_input_control_id: null,
        uuid: uuidv4(),
        prompt: "Information",
        values: [
          {
            value: editorContent?.innerHTML === "" ? "<p><br></p>" : editorContent?.innerHTML,
          },
        ],
        type: "Information",
        data: [editorContent?.innerHTML === "" ? "<p><br></p>" : editorContent?.innerHTML],
        updated_at: await GetUTCTime(),
      };
      prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls.push(informationObject);
    }
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setInputChanged(false);
    setShowInformationModal(false);
    setIsCopy(true);
  };

  return (
    <Dialog className="dialog-container-new-option" open={showInformationModal} onClose={handleClose} aria-labelledby="category-modal" aria-describedby="category-modal-description">
      <div className="close-wrapper-category">
        <CloseIcon onClick={handleClose} />
      </div>
      <DialogTitle className="editor-dialog-title">{"Information"}</DialogTitle>
      <span className="category-dialog-subtitle">Add extra information about the line.</span>
      <DialogContent className="dialog-content-new-option">
        <p className="information_text">Information</p>
        <Grid container className="input-controll">
          <Grid item xs={12} sm={12}>
            <FroalaWithoutDropdown
              copy={isCopy}
              showAI={false}
              commentData={information}
              editorData={convertFontToSpan(editorContent)}
              editor={editorRef}
              id={"info-text-editor"}
              onChange={handleChange}
              froalaConfig={froalaConfig}
              toolbarButtonsConfig={toolbarButtonsConfig}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="new-category-modal-action">
        <Button className="add-category-btn" disabled={!inputChanged} onClick={() => saveInformation()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInformation;
