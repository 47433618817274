import moment from "moment";
import Webcam from "react-webcam";
import React, { useEffect } from "react";
import { Base64 } from "js-base64";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Preloader from "../../Helpers/Preloader";
import { IRURL } from "../../Config/Api-constants";
import FilledButton from "../Button/FilledButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { sendReportCall } from "../../Utils/action";
import OutlinedButton from "../Button/OutlinedButton";
import { useRef, useState, useCallback } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "../../../Assets/Styles/Containers/_dashboard.scss";
import MarkAsComplete from "../MarkAsComplete/MarkAsComplete";
import { getInspectionData } from "../../Redux/Actions/CommonActions";
import { Button, MenuItem, Grid, Menu, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import { UpdateMedia, UpdateTemplateDataSpecificWithLoader, markReportCompleteISNSendReport, porchPdfGenerate } from "../../Helpers/ApiCall";
import { useSelector } from "react-redux";
import { splitDate } from "../../Helpers/HelperFunctions";
import SendReportConfirmModal from "./SendReportConfirmModal";
import DeleteCoverPhoto from "./DeleteCoverPhoto";
import { useDropzone } from "react-dropzone";

// const WebcamComponent = () => <Webcam />;

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user",
  // facingMode: { exact: "environment" }
};

const CoverPhotoSection = ({ inspectionData }) => {
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [camera, setCamera] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleModalOpen = () => setModalOpen(true);
  const [openModal, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const isTabOrMobile = useMediaQuery("(min-width:900px)");
  const [showStaticBackground, setShowStaticBackground] = useState(false);
  const [staticBG, setStaticBG] = useState(false);
  const [openSendReportModal, setOpenSendReportModal] = useState(false);
  const [inspectionResponse, setInspectionResponse] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDrop = React.useCallback((acceptedFiles) => {
    handleAddMedia(acceptedFiles[0], "drag");
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: "image/jpeg, image/png,image/png", onDrop });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenCamera = () => {
    setAnchorEl(null);
    setCamera(true);
  };
  const storeData = useSelector((state) => state.CommonReducer);
  const apidata = useSelector((state) => state.ResDataReducer);
  const rwApiData = apidata.rw_api_inspectionData?.data;
  const webcamRef = useRef(null);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const sendReport = () => {
    if (Base64.decode(sessionStorage.getItem("AC")) === "Palmtech") {
      setLoader(true);
      const formdata = {
        // status_date: moment(new Date().toUTCString()).format("YYYY-MM-DD HH:mm:ss"),
        status_date: new Date().toISOString()?.replace("T", " ").substring(0, 19),
        company_id: rwApiData.company.id,
        user_id: rwApiData.user_id,
        role_id: rwApiData.role_id,
        guid: rwApiData.guid,
        status: "published",
      };
      sendReportCall(
        (response) => {
          const { message, success } = response.data;
          if (success) {
            toast.success(message);
            let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem("AC"));
            let guid = sessionStorage.getItem("guid");
            let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
            dispatch(getInspectionData({ guid: guid, associate_company: sessionAssociateCompanyKey, user_id: sessionUserId }));
            setLoader(false);
            setOpenSendReportModal(false);
            let formData = {
              guid: guid,
              user_id: rwApiData.user_id,
              company_id: rwApiData.company.id,
              status: "published",
            };
            porchPdfGenerate(dispatch, formData);
          } else {
            toast.error(message);
            setOpenSendReportModal(false);
            setLoader(false);
          }
        },
        formdata,
        "SEND_REPORT"
      );
    } else {
      let guid = sessionStorage.getItem("guid");
      let formData = {
        inspection_guid: guid,
        order_id: guid,
      };
      markReportCompleteISNSendReport(dispatch, formData);
    }
  };

  const deleteCoverPhoto = async () => {
    setShowDeleteModal(false);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    const updatedObj = {
      ...prevData,
      cover_photo: {
        ...prevData.cover_photo,
        is_deleted: 1,
        updated_at: await GetUTCTime(),
      },
    };
    const filteredObj = {
      cover_photo: updatedObj.cover_photo,
      guid: updatedObj.guid,
    };

    UpdateTemplateDataSpecificWithLoader(dispatch, prevData, filteredObj);
  };

  const getTime = (apidate) => {
    let date = new Date(apidate);
    let formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
    var localTime = moment.utc(formattedDate).toDate();
    localTime = moment(localTime).format("YYYY-MM-DD HH:mm:ss");
    let dateTime = localTime.split(" ");
    let time = splitDate(dateTime).time;
    let displayDate = moment(apidate).format("MM/DD/YYYY");
    let newDisplayDate = moment.utc(apidate).local().format("MM/DD/YYYY h:mm a");
    return `${newDisplayDate}`;
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    // setImage(imageSrc);
  }, [webcamRef]);

  const handleAddMedia = async (e, action) => {
    let formData = new FormData();
    let file;
    if (action == "drag") {
      file = e;
    } else {
      file = e?.target?.files[0];
    }
    if (!file.type.match(/(image\/(png|jpg|jpeg))/) || file?.name?.toLowerCase()?.endsWith(".jfif")) {
      toast.error(`Selected file format is not supported.`);
    } else {
      if (action == "drag") {
        setImage(e);
      } else {
        setImage(e?.target?.files[0]);
      }
      let imgObj = {
        id: uuidv4(),
        url: null,
        name: file.name,
        size: file.size,
        type: file.type,
        edited: false,
        newUrl: null,
        online: "online",
        filetype: "image",
        localUrl: null,
        OriginUrl: null,
        editCount: 0,
        onlineUrl: null,
        orginName: null,
        thumbnail: null,
        is_deleted: 0,
        order: 0,
        updated_at: await GetUTCTime(),
        description: null,
        OriginlocalUrl: null,
        isOriginUploaded: true,
      };
      let img_obj_string = JSON.stringify(imgObj);
      let params = {};
      params = {
        image: file,
        inspection_guid: sessionStorage.getItem("guid"),
        img_object: img_obj_string,
        img_type: "cover",
      };
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      // setTimeout(function() {
      UpdateMedia(dispatch, formData);
      // }, 3000);
      fileInput.current.value = null;
      fileInput2.current.value = null;
    }
  };

  useEffect(() => {
    if (storeData.inspectionData) setInspectionResponse(storeData.inspectionData?.data);
  }, [storeData.inspectionData]);

  useEffect(() => {
    if (inspectionData?.cover_photo !== null) {
      let imgurl = `${inspectionData?.bucket_url}${inspectionData?.cover_photo?.onlineUrl}`;
      setImage(imgurl);
    } else setImage("");
  }, [inspectionData]);

  useEffect(() => {
    if (storeData?.loader) setLoader(true);
    else {
      // loader && toast.success(storeData?.inspectionData?.message)
      setLoader(false);
    }
  }, [storeData?.loader]);

  useEffect(() => {
    if (storeData.inspectionData?.data?.get_templates && storeData.inspectionData?.data?.get_templates === 1) {
      setShowStaticBackground(true);
    } else setShowStaticBackground(false);
    if (storeData?.staticBG === true || storeData?.staticBG === undefined) setStaticBG(true);
    else setStaticBG(false);
  }, [storeData.inspectionData?.data]);

  useEffect(() => {
    if (storeData?.reportSend) {
      toast.success("Inspection report has been sent successfully.");
    }
  }, [storeData?.reportSend]);

  return (
    <>
      <Preloader showPreloader={loader} />
      <div className="cover__header">
        {showStaticBackground || staticBG ? (
          <Typography>
            <b>---------------------</b>
          </Typography>
        ) : (
          <>
            <Typography className="address">
              {sessionStorage.getItem("location_address") !== "null" && sessionStorage.getItem("location_address") !== "" ? `${sessionStorage.getItem("location_address")},` : ""}{" "}
              {sessionStorage.getItem("location_address_2") !== "null" && sessionStorage.getItem("location_address_2") !== "" ? `${sessionStorage.getItem("location_address_2")},` : ""}{" "}
              {`${sessionStorage.getItem("location_city")} `}
              {`${sessionStorage.getItem("location_state")} `?.toUpperCase()} {sessionStorage.getItem("location_zip")}
            </Typography>
          </>
        )}

        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <div
              className={`cover_pic ${!image ? "cover_border" : ""}`}
              id="add_cover_photo_container"
              style={{ border: isDragActive && "2px dashed #4282e2" }}
              // onClick={!image? () => fileInput.current.click():undefined}
            >
              <input ref={fileInput} type="file" accept="image/*" onChange={handleAddMedia} style={{ display: "none" }} />
              <div>
                {/* {camera && !image && (
                  <div className="webcam-container">
                    <Webcam audio={false} height={250} ref={webcamRef} screenshotFormat="image/jpeg" width={250} videoConstraints={videoConstraints} />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        capture();
                      }}
                    >
                      Capture
                    </button>
                  </div>
                )} */}
                {staticBG ? (
                  ""
                ) : image ? (
                  <div style={{ background: "#f1f3f4", width: "100%" }}>
                    <span id="edit_cover_pic" className="cover_pic_edit" onClick={image ? () => fileInput2.current.click() : undefined}>
                      <EditIcon id="edit_cover_pic_plus" />
                    </span>
                    <input ref={fileInput2} type="file" accept="image/*" onChange={handleAddMedia} style={{ display: "none" }} />
                    <img
                      className="team-prof-img"
                      {...getRootProps()}
                      onClick={(e) => e?.stopPropagation()}
                      style={{ maxHeight: "-webkit-fill-available", maxWidth: "-webkit-fill-available" }}
                      src={typeof image === "string" ? image : URL.createObjectURL(image)}
                      alt="Thumb"
                    />
                    {/* <p
                      className="image__label"
                      onClick={() => {
                        //    setShowProfRemoveDialog(true);
                        setImage("");
                      }}
                    >
                      <DeleteIcon style={{ color: "#E30303" }} />
                      <span>Remove photo</span>
                    </p> */}
                  </div>
                ) : (
                  <div>
                    {
                      // !isTabOrMobile ? (
                      //   <Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
                      //     <AddCircleIcon />
                      //     <div>Click to add cover photo</div>
                      //   </Button>
                      // ) :
                      <div {...getRootProps()} id="add_cover_photo" onClick={() => (!image ? fileInput.current.click() : undefined)}>
                        <AddCircleIcon id="add_cover_photo_plus" />
                        <p id="add_cover_photo_text" style={{ textDecoration: "underline", color: "#4282e2" }}>
                          Click to add cover photo
                        </p>
                        <span>or drag and drop</span>
                      </div>
                    }
                    {/* <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      {...getRootProps()}
                    >
                      <MenuItem onClick={() => (!image ? fileInput.current.click() : undefined)}>Upoload from my device</MenuItem>
                      <MenuItem onClick={handleOpenCamera}>Camera</MenuItem>
                    </Menu> */}
                  </div>
                )}
              </div>
            </div>
            {image && (
              <div className="coverpic_delete">
                <span id="delete_cover_pic_text" onClick={() => setShowDeleteModal(true)}>
                  <DeleteIcon id="delete_cover_pic_icon" />
                  Delete photo
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6} style={{ paddingTop: "56px" }}>
            {sessionStorage.getItem("AC") !== null && sessionStorage.getItem("AC") !== undefined && Base64?.decode(sessionStorage.getItem("AC")) === "Palmtech" ? (
              <FilledButton
                id="mark_complete__btn"
                disabled={
                  rwApiData?.order_status === "Cancelled" || rwApiData?.order_status === "Complete" || rwApiData?.order_status === "Published" || rwApiData?.order_status === "Draft" ? true : false
                }
                onClick={handleModalOpen}
                width="100%"
                text="mark complete"
              />
            ) : (
              <FilledButton id="mark_complete__btn" disabled={rwApiData?.status === 6 ? true : false} onClick={handleModalOpen} width="100%" text="mark complete" />
            )}

            <br />
            <br />
            <br />
            <OutlinedButton id="report_preview__btn" width="100%" onClick={() => window.open(`${IRURL()}?guid=${rwApiData?.guid}`, "_blank")} text="Report preview" />
            <div className="status-container">
              <div>
                <p className="status-heading">PUBLISHED </p>
                {sessionStorage.getItem("AC") !== null && sessionStorage.getItem("AC") !== undefined && Base64.decode(sessionStorage.getItem("AC")) === "Palmtech" ? (
                  <p>
                    {rwApiData?.order_status === "In-Progress" || rwApiData?.order_status === "Complete"
                      ? "Unpublished"
                      : rwApiData?.order_status === "Published"
                      ? "Published"
                        ? `Published ${rwApiData?.publish_date !== null ? getTime(rwApiData?.publish_date) : ""}`
                        : "Unpublished"
                      : "Unpublished"}
                  </p>
                ) : (
                  <p>{rwApiData?.status === 6 ? `Published ${rwApiData?.isn_report_published_on !== null ? getTime(rwApiData?.isn_report_published_on) : ""}` : "Unpublished"}</p>
                )}
              </div>
              {sessionStorage.getItem("AC") !== null && sessionStorage.getItem("AC") !== undefined && Base64.decode(sessionStorage.getItem("AC")) === "Palmtech" ? (
                rwApiData?.order_status === "Published" || rwApiData?.order_status === "Complete" ? (
                  rwApiData?.order_status === "Complete" ? (
                    <div style={{ marginTop: "30px" }}>
                      <OutlinedButton width={{ height: "10px", width: "128px" }} onClick={() => setOpenSendReportModal(true)} text="Send Report" />
                    </div>
                  ) : (
                    <div style={{ marginTop: "30px" }}>
                      <OutlinedButton id="send_report__btn" width={{ height: "10px", width: "128px" }} onClick={sendReport} text="Send Report" />
                    </div>
                  )
                ) : (
                  ""
                )
              ) : rwApiData?.status === 6 ? (
                <div style={{ marginTop: "30px" }}>
                  <OutlinedButton width={{ height: "10px", width: "128px" }} onClick={sendReport} text="Send Report" />
                </div>
              ) : (
                ""
              )}
            </div>
          </Grid>
        </Grid>
        <SendReportConfirmModal
          openSendReportModal={openSendReportModal}
          setOpenSendReportModal={setOpenSendReportModal}
          sendReport={sendReport}
          rwApiData={rwApiData}
          paymentEnable={rwApiData?.company?.is_payment_enable}
          agreementEnable={rwApiData?.company?.is_agreement_enable}
        />
        <MarkAsComplete
          open={openModal}
          handleModalClose={handleModalClose}
          onClose={handleModalClose}
          isPaid={rwApiData?.paid}
          isSigned={rwApiData?.is_agreement_signed}
          companySettings={rwApiData?.company}
          guid={rwApiData?.guid}
          userID={rwApiData?.user_id}
          roleID={rwApiData?.role_id}
          orderStatus={rwApiData?.order_status}
          paymentEnable={rwApiData?.company?.is_payment_enable}
          agreementEnable={rwApiData?.company?.is_agreement_enable}
          agreementCount={rwApiData?.total_agreements}
        />
        <DeleteCoverPhoto setShowDeleteModal={setShowDeleteModal} showDeleteModal={showDeleteModal} deleteCoverPhoto={deleteCoverPhoto} />
      </div>
    </>
  );
};

export default CoverPhotoSection;
